<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      v-if="!data_loaded"
      class="mt-10"
    ></v-skeleton-loader>
    <!--/ sekeletion -->

    <v-card elevation="0" v-if="data_loaded">
      <v-card-title class="font-weight-bold">
        <h4>اللغات</h4>
        <v-spacer></v-spacer>
        <v-btn @click="lang_dialog = true" color="primary">اضافة لغة</v-btn>
      </v-card-title>
      <v-data-table
        :footer-props="$store.state.footer_props"
        :items="languages"
        :headers="headers"
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="edit_lang(item)" small icon>
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn @click="open_delete_dialog(item.id)" small icon>
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
        <!--/ actions -->
      </v-data-table>
      <!--/ datatable -->
    </v-card>
    <!--/ card -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"> حذف اللغة ؟ </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_lang()"
            depressed
            color="error"
            >حذف</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حذف اللغة</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-dialog persistent max-width="500" v-model="lang_dialog">
      <v-card>
        <v-card-title> انشاء لغة </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form">
            <v-row class="pt-4">
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="[(v) => !!v || 'حقل مطلوب']"
                  v-model="lang.lang_name"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    اسم اللغة
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="[(v) => !!v || 'حقل مطلوب']"
                  v-model="lang.locale"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    اختصار اللغة
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :rules="[(v) => !!v || 'حقل مطلوب']"
                  v-model="lang.dir"
                  :items="['ltr', 'rtl']"
                >
                  <template slot="label">
                    <strong class="red--text">*</strong>
                    اتجاه اللغة
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            :disabled="save_loader"
            :loading="save_loader"
            @click="save()"
            small
            color="primary"
            >حفظ</v-btn
          >
          <v-btn small @click="close()" text color="error">الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <!--/ container -->
</template>

<script>
export default {
  name: "employees",

  data() {
    return {
      save_loader: false,
      lang_dialog: false,
      delete_id: null,
      delete_loader: false,
      snackbar_success: false,
      delete_dialog: false,
      data_loaded: true,
      languages: [],
      headers: [
        {
          value: "lang_name",
          text: "اسم اللغة",
          align: "center",
        },
        {
          value: "dir",
          text: "اتجاه اللغة",
          align: "center",
        },
        {
          value: "locale",
          text: "اختصار اللغة",
          align: "center",
        },
        {
          value: "actions",
          text: "أجراءات",
          align: "center",
        },
      ],

      lang: {
        lang_name: "",
        dir: "",
        locale: "",
      },
    };
  },
  methods: {
    get_langs() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "languages",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.languages = Object.assign([], res.data.data);
        })
    },
    open_delete_dialog(id) {
      this.delete_id = id;
      this.delete_dialog = true;
    },
    delete_lang() {
      if (!this.delete_loader) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `languages/${this.delete_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            let lang_index = this.languages.findIndex(
              (e) => e.id == this.delete_id
            );

            this.languages.splice(lang_index, 1);
            this.delete_loader = false;
            this.delete_dialog = false;
            this.snackbar_success = true;
          });
      }
    },
    edit_lang(item) {
      this.lang = Object.assign({}, item);
      this.lang_dialog = true;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.lang.id ? `languages/${this.lang.id}` : "languages",
              method: this.lang.id ? `put` : "post",
            },
            data: this.lang,
          })
          .then((res) => {
            this.get_langs();
            this.close();
          })
      }
    },
    close() {
      this.lang = Object.assign(
        {},
        {
          lang_name: "",
          dir: "",
          locale: "",
        }
      );

      this.$refs.form.resetValidation();
      this.lang_dialog = false;
      this.save_loader = false;
    },
  },
  mounted() {
    // if (this.$store.state.user_menu_dashboard.is_admin == 1) {
    //   if (!this.$route.query.store) {
    //     this.$router.push("/Stores");
    //   }
    // }
    this.get_langs();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--switch {
    .v-input__slot {
      justify-content: center;
    }
  }
}
</style>
